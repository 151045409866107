<template>
  <div>
    <!-- <loader2 v-if="load == true"></loader2> -->
    <section class="breadcrumb-outer text-center bg-orange">
      <div class="container">
        <h2>{{ $t("Header.Online.Online") }}</h2>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row">
          <div
            v-for="(elon, i) in elons"
            :key="i"
            class="col-md-4 col-sm-12 mar-bottom-30"
          >
            <div class="blog-post_wrapper image-wrapper">
              <div
                class="blog-post-image lazyload blur-up"
                style="height: 230px"
              >
                <img
                  :data-src="
                    'https://backend.scocenter.uz/storage/' + elon.image
                  "
                  alt="image"
                  height="200px"
                  class="img-responsive center-block post_img lazyload blur-up"
                />
              </div>
              <div class="post-content" style="color: #3498db; height: 240px">
                <div>
                  <h4>
                    <router-link :to="'/adds-detail/' + elon.id">
                      {{ elon[`title_${$i18n.locale}`] | kes }}
                    </router-link>
                  </h4>
                </div>
                <div style="display: flex; margin-top: 2%; margin-bottom: 2%">
                  <div>
                    <strong>
                      <i class="fa fa-calendar"></i> {{ $t("start") }}</strong
                    >
                    <span>{{ elon.ads_start }}</span>
                  </div>
                  <div style="margin-left: 10%">
                    <strong
                      ><i class="fa fa-calendar"></i>{{ $t("finish") }}</strong
                    >
                    <span>{{ elon.ads_end }}</span>
                  </div>
                </div>
                <div class="entry-meta-content">
                  <p>{{ elon[`description_${$i18n.locale}`] }}</p>
                </div>
                <!--./ entry-meta-content -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "../apis";
export default {
  data() {
    return {
      elons: "",
      load: true,
    };
  },
  methods: {
    async makeRequest() {
      try {
        const res = await api.get("/notification").then((response) => {
          setTimeout(() => (this.load = false), 500);
          this.elons = response.data.notification;
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    return this.makeRequest();
  },
};
</script>
<style scoped>
.bg-orange {
  margin: 0;
  background-color: #3498db !important;
  background-image: none;
}
a {
  background: linear-gradient(to right, #3498db, #3498db);
  color: #3498db;
  background-position: left 87%;
  background-repeat: no-repeat;
  transition: all ease-in-out 0.5s;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  background-size: 0px 3px;
  padding-bottom: 10px;
}
a:hover {
  text-decoration: none !important;
  background-size: 100% 3px;
  transition: all ease-in-out 0.5s;
}
section {
  padding: 40px 0 !important;
  position: relative;
}
.breadcrumb-outer h2 {
  padding: 70px 0 15px !important;
}
.d-flex {
  display: flex;
  flex-direction: column;
}
.blog-post_wrapper {
  overflow: inherit;
  height: auto;
  background-color: transparent;
  border-radius: 0;
  box-shadow: 0 0 15px #cccccc60;
}
.blog-post_wrapper.image-wrapper:before {
  display: none;
}
.blog-post_wrapper .blog-post-image {
  overflow: hidden;
  position: relative;
}
.blog-post_wrapper .blog-post-image:before {
  content: "";
  background: #00000057;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.blog-post_wrapper.image-wrapper .post-content {
  position: relative;
  top: inherit;
  bottom: 0;
  padding: 15px;
}
.blog-post_wrapper h3 span {
  font-size: 20px;
  text-transform: uppercase;
  display: block;
}
@media (max-width: 400px) {
  .breadcrumb-outer h2 {
    padding: 100px 0 15px !important;
  }
}
</style>